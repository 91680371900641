header.banner {
  padding: $spacer * 1 0;
  @media(min-width: 1200px) {
    padding: $spacer * 2 0;
    &.banner-sidebar {
      height: 120px;
    }
  }

  img.logo {
    width: 140px;
    height: auto;
  }

  .heading-desktop {
    @media (max-width: 1200px) {
      visibility: hidden;
      height: 0;
    }
  }

  .heading {
    text-align: center;
    @media (max-width: 1200px) {
      flex-direction: column-reverse;
    }

    h2 svg {
      width: 35px;
      height: 35px;
    }

    h1, h2 {
      margin-bottom: 0;
    }

    h1 {
      font-size: 2rem;
    }

    @media (max-width: 1200px) {
      h1 {
        margin-top: $spacer;
        font-size: 1.5rem;
      }
    }

    h2 {
      margin-top: $spacer * 0.5;
      margin-bottom: $spacer * 0.5;
      color: $black;
      text-align: center;
      @media (min-width: 1200px) {
        text-align: left;
      }
    }
  }

  .container-fluid { 
    padding-left: 50px;
    padding-right: 50px;
  }

  .container,
  .container-fluid { 
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1200px) {
      flex-direction: column-reverse;
    }
  }
}

header.dashboard {
  height: 101px;
  display: flex;
  align-items: center;
    .container {
      justify-content: flex-end;
      display: flex;
      align-items: center;

      a {
        color: $purple;
      }

      .dropdown-menu {
        padding: 0;
        background: $purple;
        a {
          color: $white;
          &:hover {
            background: darken($purple, 20%);
          }
        }
      }
    }
}

.heading-mobile {
  h2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #3C3C3B;
    text-align: center;

    svg {
      width: 35px;
      height: 35px;
    }
  }
}
