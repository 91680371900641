// Typography
$font-family-sans-serif: 'Mulish', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$spacer: $font-size-base;

// Colors
$black: #3C3C3B;
$grey: #B2B2B2;
$purple: #835685;
$pink: #D75181;
$white: #ffffff;
$primary: $purple;
$red: #dc3545;
$green: #abdbd4;
$zirconia-green: #8ACBC1;
$light-grey: #FAFAFA;

$theme-colors: (
  primary: #835685,
  secondary: #abdbd4,
  digital: #7ac4b2,
  endo: #ef4d89,
);

// Body
$body-bg: $white;

$headings-color: $purple;

$h1-font-size: $font-size-base * 3.125;
$h2-font-size: $font-size-base * 1.25;

$btn-border-radius:    0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

$card-border-radius: 0;

$dropdown-border-radius: 0;
