#sidebar {
  width: 250px;
  height: 100vh;
  background: $purple;

  div {
    background: $white;
    padding: $spacer;
    img {
      display: block;
      margin: auto;
      width: 120px;
      height: auto;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;

    li a {
      display: block;
      color: $white;
      background: $purple;
      padding: $spacer;
      border-bottom: 1px solid $white;
      transition: background 0.3s linear;
  
      &:hover {
        text-decoration: none;
        background: darken($purple, 20%);
      }
    }
  }
}
