// Fonts
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@500;600;700&display=swap');

// Dropzone
@import '~dropzone/dist/dropzone.css';

// Magnific Popup
@import "~magnific-popup/dist/magnific-popup.css";

// Glide JS
@import "~@glidejs/glide/src/assets/sass/glide.core";

// Variables
@import 'variables';

@import 'global';
@import 'header';
@import 'rooms';
@import 'resources';
@import 'hotspots';
@import 'forms';
@import 'sidebar';
@import 'sample';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

h1.home-heading {
  font-size: 2.2rem !important;
}
