.hotspot {
  position: absolute;
  width: 80px;
  height: 80px;

  .inner-text {
    position: absolute;
    top: 27px;
    left: 0;
    text-align: center;
    color: $purple;
    width: 100%;
    line-height: 1.3;

    span {
      font-size: 0.7rem;
      line-height: 1.3;
      display: block;
    }
  }

  a {
    display: block;
    text-align: center;
  }

  svg {
    width: 50%;
    height: 50%;

    @media(max-height: 850px) {
      width: 50% !important;
      height: 50% !important;
    }

    @media(min-width: 992px) {
      width: 100%;
      height: 100%;
    }

    &.animate {
      position: absolute;
      top: -2px;
      left: 20px;

      @media(min-width: 992px) {
        top: -4px;
        left: 0;
      }

      @media(max-height: 850px) {
        top: -2px !important;
        left: 20px !important;
      }
    }
  }

  .hotspot-content {
    border: 2px solid $grey;
    background: rgba(255,255,255,0.8);
    padding: $spacer;
    width: 350px;
    position: absolute;
    left: 110px;
    top: 0;
    transform: scale(0);
    opacity: 0;
    z-index: 100;
    transform-origin: 0% 0% 0px;
    display: none;

    a {
      color: $pink;
      font-weight: 700;
      text-align: left;
    }

    .close {
      position: absolute;
      top: 0;
      right: 5px;
      float: none;
      clear: both;
    }
  }

  &.large {
    width: 180px;
    height: 180px;

    svg {
      width: 100% !important;
      height: 100% !important;
    }

    svg.animate {
      z-index: 100;
      top: -7px !important;
      left: 0 !important;
    }
  }
}

.hotspot-select {
  cursor: pointer;

  .hotspot {
    transition: left .3s linear, top .3s linear;
  }
}

#add-hotspot {
  .hotspot {
    left: 0;
    top: 0;
  }
}

.desktop-view {
  .hotspot-inner {
    display: none;
    &.visible {
      display: none;
      @media(min-width: 1200px) {
        text-align: center;
        line-height: 1.3;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 0.9rem;
      }
    }

    span {
      margin-top: -35px;
    }
  }

  .hotspot-hover {
    position: absolute;
    left: -20px;
    top: 70px;
    width: 120px;
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    border-radius: 2px;
    opacity: 0;
    display: none;
    padding: 2px;
    line-height: 1.3;

    @media(max-height: 850px) {
      top: 45px;
    }
  }
}

.mobile-view {
  .empty {
    display: none;
  }

  .room-nav {
    display: block;
    columns: 1;

    li {
      width: 100%;
      margin-bottom: $spacer;

      a {
        display: block;
      }
    }

    @media(min-width: 768px) {
      columns: 2;
    }
  }

  .hotspot-hover {
    display: none;
  }

  .hotspots {
    .hotspot-col {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @media(min-width: 380px) {
        &[data-slug="free-sample"] {
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -90px;
          margin-top: -85.5px;
        }
      }

      @media(min-width: 500px) {
        &[data-slug="free-sample"] {
          margin-left: -95px;
        }
      }

      @media(min-width: 768px) {
        &[data-slug="free-sample"] {
          position: relative;
          margin: unset;
          left: unset;
          top: unset
        }
      }

      a {
        position: relative;

        .hotspot-inner {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $white;

          &.light {
            color: $black;
          }

          span {
            margin-top: -20px;
            text-align: center;
            font-size: 0.9rem;
          }
        }
      }

      svg {
        width: 180px;
        height: 100%;

        @media(min-width: 500px) {
          width: 190px;   
        }
      }
    }

    svg.animate {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.mobile-hex-block g:hover {
  cursor: pointer;
}

.mobile-hex-block { 
  display: none;
}

@media(max-width: 768px) {

  .mobile-hex-block {
    display: block;
  }

  .mobile-hotspots-home {
    display: none;
  }
}

.st0,
.st3,
.st4 {    
  filter: url(#dropshadow);
}
