.room {
  position: relative;

  @media (max-width: 1200px) {
    overflow-x: scroll;
  }
}

#app .room { 
  img {
    height: 70vh;

    @media(min-width: 1920px){
      height: 70vh;
    }
    width: auto;
  }
}

.hotspot-wrap {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#app .container.has-sidebar {

  @media(max-width: 1200px) {
    margin: auto;
  }
}

@media(max-width: 992px){
  .room-container {
    max-width: 100% !important
  }
}

.mobile-view .room {
  overflow-x: hidden;
  overflow-y: hidden;
  .hotspot-inner {
    display: none;
  }
}

.categories ul button, 
.categories ul a {
  display: block;
  width: 100%;
}
