.video-popup {
  display: block;
  position: relative;

  &::after {
    content: "";
    background: rgba(0,0,0,0.1);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .play {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    margin-left: -20px;
    margin-top: -20px;
    z-index: 999;
  }
}

.image-popup {
  display: block;

  img {
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }

  strong {
    color: $pink;
  }

}

ul.nav-section {
  display: flex;
  justify-content: space-between;
  font-size: $font-size-base * 0.9;
  text-align: center;
  font-weight: bold;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
  border-bottom: $purple $spacer * 0.5 solid;

  a {
    display: block;
    color: $black;
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }

  li {
    max-width: 112px;
  }
}

.nav-file-type {
  display: flex;
  justify-content: center;  
  font-weight: bold;
  font-size: $font-size-base * 0.9;

  li a {
    padding: 0 $spacer;
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }
}

.downloads {
  padding-left: 0;
  list-style-type: none;

  li a {
    display: flex;
    justify-content: space-between;
    padding: $spacer * 0.5 0;
    color: $black;

    &:hover {
      text-decoration: none;
    }

    span {
      display: flex;
      align-items: center;

      &.file {
        width: 100%;
        padding-right: $spacer * 0.3;
      }

      &.download {
        color: $purple;
      }
    }
  }

  li.empty {
    text-align: center;
    margin-top: $spacer * 3;
  }
}

li.active a {
  color: $pink;

  &.highlight {
    color: $pink !important;
  }
}

.glide__arrow img {
  width: 20px;
}

.table-wrap {
  @media (min-width: 992px) {
    margin-top: 60px;
  }
}
