body.zirconia {

  h1,
  h2,
  h3,
  h4,
  h5,
  .h5 {
    font-weight: 700;
  }

  .h5 {
    @media(max-width: 768px){
      font-size: 1.1rem;
    }
  }

  .alert-success {
    background-color: $zirconia-green;
    color: $black;
  }

  header {
    position: relative;

    .logo {
      width: 170px;
      height: 63px;
    
      @media(min-width: 550px){
        width: 200px;
        height: 74px;
      }
    }

    .logo-white {
      display: block;
      margin-bottom: 70px;
      @media(min-width: 550px){
        display: none;
      }
    }

    .logo-dark {
      display: none;
      @media(min-width: 550px){
        display: block;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        display: flex;
        padding: $spacer * 0.2 0;
      }

      strong {
        font-weight: 700;
      }
    }

    .angles {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      overflow: hidden;
    }

    .img-wrap {
      display: flex;
      justify-content: center;
    }

    .sample-image {
      position: relative;
      display: inline-block;
    }

    .sample-badge {
      width: 100px;
      position: absolute;
      top: 3px;
      right: 0;

      @media(min-width: 600px) {
        right: -20px;
        width: 140px;
      }

      @media(min-width: 1199px) {
        width: 160px;
      }
    }

    h1 {
      font-size: 2.8rem;
      line-height: 1;

      @media(min-width: 768px) {
        font-size: 3.8rem;
      }

      @media(min-width: 992px) {
        width: 80%;
      }

      @media(min-width: 1199px) {
        font-size: 4.8rem;
      }
    }
    
    p {
      @media(min-width: 992px) {
        width: 65%;
      }
    }

    small {
      line-height: 1.2;
      display: block !important;
    }
  }

  .header-grid {
    display: grid;
    @media(min-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
    grid-template-columns: 1fr;
    grid-gap: ($spacer * 2);
    z-index: 100;
    position: relative;
    padding-top: 10px;

    @media(min-width: 550px){
      padding-top: 110px;
    }

    @media(min-width: 1199px){
      padding-top: 90px;
    }
  }

  .card {
    margin: $spacer 0 0 0;
    align-self: flex-start;
    border-radius: 15px;
    border: 5px $zirconia-green solid;
    .card-header {
      border: none;
      background: $zirconia-green;
    }
  }

  .sample-bar {
    background: $zirconia-green;
    cursor: pointer;

    img {
      display: none;
      @media(min-width: 768px){
        display: block;
      }
    }

    h3 {
      font-size: 1.1rem;
      text-align: center;

      @media(min-width: 768px){
        font-size: 1.3rem;
        text-align: left;
      }

      @media(min-width: 992px) {
        font-size: 1.75rem;
      }
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      @media(min-width: 768px){
        justify-content: space-between;
      }
    }
  }

  #free-sample {
    background: $light-grey;

    #free_sample_id {
      display: flex;
      @media(min-width: 992px) {
        justify-content: flex-end;
      }
    }

    .form-control:focus,
    .custom-select:focus {
      border-color: $zirconia-green;
      box-shadow: 0 0 0 0.2rem rgba(138, 203, 193, 0.25);
    }

    button {
      width: 50%;
      font-weight: 700;
      span {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: none;
        }
      }
    }

    .container {
      display: grid;
      gap: $spacer;
      grid-template-columns: 1fr;

      @media(min-width: 992px) {
        grid-template-columns: 1fr 1fr;

        div:nth-child(1),
        div:nth-child(6),
        div:nth-child(11),
        div:nth-child(12) {
          grid-column: 1/3;
        }
      }
    }
  }
}
