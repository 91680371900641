#app {
  height: calc(100vh - 154px);

  @media (max-width: 1200px) {
    height: calc(100vh - 181px);
  }

  @media (max-width: 992px) {
    height: calc(100vh - 233px);
  }

  @media (max-width: 1200px) {
    overflow-x: hidden;
  }

  &.has-sidebar {
    height: auto;

    .app-container.education-dental-translate {
      transform: translateY(-190px);
    }

    .app-container.education-digital-translate {
      transform: translateY(-190px);
    }  

    .app-container.education-endo-translate {
      transform: translateY(-190px);
    }  

    .app-container.the-lab-digital-translate {
      transform: translateY(-130px);
    }

    .app-container.sales-marketing-digital-translate {
      transform: translateY(-160px);
    }

    .app-container.sales-marketing-endo-translate {
      transform: translateY(-160px);
    }


    .app-container.sales-marketing-dental-translate {
      transform: translateY(-160px);
    }

    .app-container.translate-small {
      @media(min-width: 1200px) {
        transform: translateY(-30px);
      }
    }

    @media(min-width: 1200px) {
      height: calc(100vh - 120px);
    }
  }

  .app-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: inherit;
    &.has-sidebar {
      align-items: flex-start;
      height: auto;
    }
  }
}

.h3 {
  font-weight: 700;
}

.media-img {
  max-width: 100%;
  width: auto;
  height: 150px;
  object-fit: contain;
  padding: $spacer;
}

video {
  width: 100%;
}

.back-img {
  width: 200px;
  height: auto;
  @media (max-width: 768px) {
    margin-top: $spacer;
  }
}

#wrap {
  display: flex;

  main {
    flex: 1;
  }
}

.media-preview {
  embed {
    width: 100%;
    height: 700px;
  }
}

.video-container {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  left: 0;
  z-index: 99999;

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
  }
}

#intro-video {
  overflow: hidden;
  z-index: 9999;
  display: none;
  @media(min-width: 992px) {
    display: block;
  }
}

.table-height {
  max-height: 450px;
  overflow: scroll;
}

label sup {
  color: $red;
}

.button-wrapper {
  display: none;
  position: relative;
  margin: 0 $spacer;
  ul {
    z-index: 200;
  }

  @media(min-width: 1200px) {
    display: block;
    position: absolute;
    top: 0;
    width: 250px;
    right: 50px;
    margin: 0 0 ($spacer * 0.5) 0;
  }

  ul {
    margin: 0;
    position: relative;
    list-style-type: none;
    padding-left: 0;
    @media(min-width: 1200px) {
      width: 200px;
    }

    li {
      margin-bottom: $spacer * 0.5;

      a {
        display: block;
        min-width: 165px;
      }
    }
  }
}

body.overflow {
  overflow-y: visible;
  @media(min-width: 1200px) {
    overflow-x: hidden;
  }
}

.mfp-bottom-bar {
  margin-top: -25px !important;
}

.mfp-counter {
  display: none !important;
}

a.highlight {
  color: $purple !important;
}

#page-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  @media(min-width: 1200px) {
    flex-direction: row;
  }

  main {
    flex: 1;
  }
}

.text p {
  font-size: 0.9rem;
}

aside.sidebar {
  margin: $spacer;
  z-index: 100;
  background:rgba(255, 255, 255, 0.8);

  @media(min-width: 1200px){
    display: none;
  }

  .downloads {
    @media(min-width:768px){
      columns: 2;
    }
    padding-bottom: 0 !important;
    @media(min-width: 1200px) {
      margin-bottom: 0;
      columns: 1;
    }

    li a {
      line-height: 1.1;

      span.download {
        line-height: 1.1;
      }
    }
  }

  @media(min-width: 1200px) {
    position: absolute;
    right: 50px;
    width: 350px;
    margin: 0;
    padding: 0 $spacer $spacer $spacer;
  }
  @media(min-width: 1560px) {
    width: 400px;
  }

  @media(min-width: 1200px) {
    .downloads a {
      font-size: 0.8rem;
    }
  }

  @media(min-width: 1560px) {
    .downloads a {
      font-size: 0.7rem;
    }
  }
  
  .empty {
    display: none;
  }
}

.logo-sidebar {
  @media(min-width: 1200px) {
    width: 250px;
    right: 25px;
    position: absolute;
  }
}

.contact {
  position: relative;
  right: 0;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    span {
      margin-top: -15px;
      text-align: center;
      position: absolute;
      font-size: 1.3rem;
      line-height: 1.2;
    }
  }

  svg {
    width: 200px;
    height: 200px;
  }

  @media(min-width: 1200px) {
    position: absolute;
    bottom: -250px;
    right: 300px;
    margin-bottom: $spacer;
  }

  @media(max-width: 1850px) {
    right: 50px;
  }

}

.categories {
  position: relative;
  margin-top: $spacer;
  display: none;

  @media(min-width: 1200px) {
    margin-top: 0;
    position: absolute;
    z-index: 100;
    display: block;

    &.position1 {
      @media(max-height: 800px) {
        bottom: -50px;
      }
      bottom: 40px;
    }

    &.position2 {
      @media(max-height: 800px) {
        bottom: -50px;
      }
      bottom: 200px;
    }
  }

  ul {
    justify-content: center;
    @media(min-width: 1200px) {
      width: 150px;
      flex-direction: column;
      justify-content: flex-start;
    }

    li {
      margin-bottom: $spacer * 0.5;
    }

    button {
      display: block;
      width: 100%;
    }
  }
}

.mobile-view .categories {
  display: block;

  ul {
    width: 100%;
    display: flex;

    li {
      flex: 1;
    }
  }
}

.btn-primary:disabled {
  background-color: $grey !important;
  border-color: $grey !important;
  color: $black !important;
}

.page-content {
  display: none;
  position: relative;
  width: 1000px;

  .grid {
    display: grid;
    grid-template-columns: 75%;

    &.has-media {
      column-gap: 2rem;
      &.media-2 {
        grid-template-columns: 255px 1fr 120px;
      }
      &.media-3 {
        grid-template-columns: 255px 1fr;
        @media(max-width: 1500px) {
          grid-template-columns: 255px 1fr 50px;

          a {
            line-height: 1.1 !important;
          }
        }

        @media(max-width: 1350px) {
          grid-template-columns: 255px 1fr 100px;
        }
      }
    }
  }

  .text {
    position: relative;
    z-index: 100;
  }
  
  strong {
    margin-bottom: $spacer * 0.5;
  }

  @media(min-width: 1200px) {
    display: block;
  }

  span.download {
    display: none;
  }

  ul.downloads {
    display: grid;
    column-gap: 0.5rem;
    padding-bottom: 0 !important;
    margin-bottom: 0;
    &.media-col-3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
    &.media-col-2 {
      grid-template-columns: 1fr 1fr;
    }
  }

  .downloads li {
    display: flex;
    align-items: center;

    a { 
      font-size: 0.7rem;
      line-height: 1.3;
      display: inline-block;
    }
  }

  .empty {
    display: none;
  }
}

.privacy {
  display: none;

  @media(min-width: 1200px) {
    position: relative;
    padding: 0 15px;
    position: fixed;
    right: 50px;
    bottom: 10px;
  }

  @media(max-height: 580px) {
    bottom: -20px;
  }

  li {
    margin-right: $spacer;
    a {
      font-size: 0.8rem;
    }
  }
}

.mfp-wrap,
.mfp-content {
  z-index: 99999 !important;
}

#form-modal {
  z-index: 9999999999 !important;
}

.desktop-view {
  visibility: hidden;
  height: 0;
  @media(min-width: 1200px) {
    visibility: visible;
    height: auto;
  }
}

.mobile-view {
  @media(min-width: 1200px) {
    display: none;
  }

  .downloads li a {
    font-size: 0.8rem;
  }
}

.modal-header-data-capture {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media(max-width: 1200px) {
    padding-bottom: 0 !important;
  }

  figure {
    margin: $spacer auto;

    img {
      width: auto;
      height: 180px;
    }

    @media(min-width: 1200px) {
      display: none;
    }
  }
}

@media(max-width: 1200px) {
  .modal-title {
    display: block;
    text-align: center;
    width: 100%;
    line-height: 1.2 !important;
  }
}

@media(min-width: 1200px) {
  .modal-title figure {
    display: none;
  }
}

.mobile-view .privacy {
  display: flex !important;
  justify-content: center;
}

.contact-mobile a{
  cursor: pointer;
}

.hotspot-row {
  margin: auto;
  justify-content: center;
  position: relative;
  @media(min-width: 380px) {
    gap: $spacer;
  }
}

.modal-footer {
  justify-content: flex-start !important;
}

.site-links {
  display: flex;
  justify-content: center;
  width: 100%;
  a {
    color: $black;
  }
}

ul.range {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  position: absolute;
  right: 100px;
  bottom: 40px;
  display: flex;
  gap: 1rem;

  @media(max-height: 800px) {
    bottom: -50px;
  }
}

button.file-toggle {
  background: none;
  color: $black;
  border: none;
  padding: 0;
  font-size: 1rem;
  width: 100%;
  text-align: left;
}

.product-tabs-desktop {
  position: relative;

  .tab-content {
    position: absolute;
    width: 100%;
    padding: 0 0.5rem 0.5rem 0.5rem;
    background: rgba(255,255,255,0.8);
    z-index: 100;

    strong {
      margin: 0.2rem 0;
    }
  }
}

.nav-tabs .nav-item {
  flex: 1;

  .nav-link {
    border-radius: 0;
    border: none;
    &.active {
      background: $purple;
      color: $white;
    }
  }
}

.grid-1 {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
}

ul.tab-downloads {
  padding: 0.5rem 0;
  @media(max-width: 992px) {
    padding: 0;
  }
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  li a {
    display: flex;
    justify-content: space-between;
    color: $black;
    font-size: 0.8rem;
    line-height: 1.3;

    @media(max-width: 992px) {
      padding: 0.5rem 0;
    }

    .download {
      color: $purple;
    }

    &:hover {
      text-decoration: none;
    }

    span {
      display: flex;
      align-items: center;

      &.file {
        width: 100%;
        padding-right: $spacer * 0.3;
        padding-bottom: 0.2rem;
      }
    }
  }
  li.no-file {
    font-size: 0.8rem;
  }
}

.btn-digital,
.btn-digital:hover  {
  color: $white !important;
}

.btn-digital .active {
  background: darken(#7ac4b2, 30%) !important;
  box-shadow: inset 0px 0px 12px rgba(0,0,0,0.2) !important;
}

.btn-endo .active {
  box-shadow: inset 0px 0px 12px rgba(0,0,0,0.2) !important;
}

.btn-primary .active {
  box-shadow: inset 0px 0px 12px rgba(0,0,0,0.2) !important;
}

.mobile-view p.description br {
  display: none !important;
}
